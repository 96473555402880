@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    @apply h-full;
}

body {
    @apply h-full;
}
